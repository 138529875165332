import { css } from 'styled-components'
import { overwriteDefaultTheme, withGlobalStyle, mediaQuery } from './'

export const defaultGridOptions = {
  mobile: {
    columns: 6,
    columnWidth: 69,
    gutterWidth: 0,
    maxWidth: 414,
  },
  tablet: {
    columns: 12,
    columnWidth: 120,
    gutterWidth: 0,
    maxWidth: 1440
  },
  desktop: {
    columns: 12,
    columnWidth: 120,
    gutterWidth: 0,
    maxWidth: 1440
  }
}

const themeProject = {
  colors: {
    'background': '#FFFFFF',
    'text': '#1B1815',
    'white': '#FFFFFF',
    'black': '#1B1815',
    'grey': '#cccdcd',
    'magenta': '#d60b52'
  },
  fonts: {
    'roboto': 'Roboto, sans-serif',
    'roboto_bold': 'Roboto, sans-serif',
    'eklips': 'Eklips, sans-serif'
  },
  ease: 'cubic-bezier(.25, .1, .25, 1)',
  col: {
    mobile: 60,
    desktop: defaultGridOptions.desktop.columnWidth
  }
}

const getRelativeCreator = (mobileValue: number, desktopValue: number) => {
  const VALUES = {
    'mobile': mobileValue,
    'desktop': desktopValue
  }

  return (from: number, type: 'mobile' | 'desktop', custom = false) => {
    return `${(from / VALUES[type]) * 100}${custom ? '%' : 'vw'}`
  }
}

export const getRawRelative = (from, to) => {
  return `${(from / to) * 100}`
}

export const getRelative = getRelativeCreator(defaultGridOptions.mobile.maxWidth, defaultGridOptions.desktop.maxWidth)


export const getTransition = (duration = .3) => ({
  duration,
  type: 'tween',
  ease: [.25, .1, .25, 1]
})

export function hex2Rgba (hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const hideScrollbar = () => css`
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 255);
    display: none;
  }
`

export const theme = overwriteDefaultTheme(themeProject)

const extraGlobalCss = `
  html {
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  body {
    background-color: ${themeProject.colors.background};
    color: ${themeProject.colors.text};
    font-family: ${themeProject.fonts.roboto};
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;

    &.slider-being-dragged {
      .slider--flickity {
        button,
        a {
          pointer-events: none !important;
        }
      }
    }
  }

  button {
    outline: 0;
    padding: 0;
    background-color: transparent;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  dl {
    outline: 0;
  }

  a {
    cursor: pointer;
    color: unset;
    text-decoration: none;
  }

  .srt-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input {
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
  }

  input::-webkit-search-cancel-button {
    display: none;
  }

  html, body {
    font-size: ${getRelative(15, 'mobile')};

    @media (min-width: 768px) {
      font-size: ${getRelative(18, 'desktop')};
    }
  }

  figure {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  .modal-as-tooltip {
    justify-content: flex-end;

    .overlay {
      opacity: .2;
    }
  }

  .slider--flickify {
    outline: none;
  }

  .flickify-viewport {
    overflow: hidden;
    position: relative;
  }


  #at-cv-lightbox-message > span {
    color: ${themeProject.colors.text};
    font-family: ${themeProject.fonts.roboto};
    text-align: center;
  }

  .at-branding-logo {
    display: none !important;
  }
`

const defaultRowOptions = {
  mobile: { rows: 'auto-flow', gutter: 0},
  desktop: { rows: 'auto-flow', gutter: 0},
}

//@ts-ignore
export const createGrid = ({ columns, rows } = {}) => {
  const { mobile, desktop } = columns ? columns : defaultGridOptions
  const _rows = rows ? rows : defaultRowOptions

  return css`
    display: grid;
    width: 100%;
    grid: ${_rows.mobile.rows} / repeat(${mobile.columns}, ${getRelative(mobile.columnWidth, 'mobile', true)});
    column-gap: ${getRelative(mobile.gutterWidth, 'mobile', true)};
    row-gap: ${getRelative(_rows.mobile.gutter, 'mobile', true)};
    justify-content: center;

    ${mediaQuery.greaterThan('tablet')`
      grid: ${_rows.desktop.rows} / repeat(${desktop.columns}, ${getRelative(desktop.columnWidth, 'desktop', true)});
      column-gap:${getRelative(desktop.gutterWidth, 'desktop', true)};
      row-gap:${getRelative(_rows.desktop.gutter, 'desktop', true)};
    `}
  `
}

export const Reset = withGlobalStyle(theme, extraGlobalCss)

export const createUnderline = ({ reverse = false, color = 'black'} = {}) => css`
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: ${({ theme }) => theme.colors[color]};
    left: unset;
    right: 0;
    transition: width 300ms ease, left 300ms ease, right 300ms ease;
  }

  ${reverse && `
    span {
      left: 0;
      right: unset;
      width: 100%;
    }
  `}

  &:hover,
  &.active {
    ${reverse ? `
      span {
        left: unset;
        right: 0;
        width: 0;
      }`
    : `
      span {
        left: 0;
        right: unset;
        width: 100%;
      }
    `}
  }
`
