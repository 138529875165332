import { css } from 'styled-components'
import { mediaQuery } from '@/styles'

/***
 * 1rem mobile -> 15px
 * 1rem desktop -> 18px
 */
export const REM_MOBILE = 15
const REM_DESKTOP = 18
export const getRelativeREM = (value, factor) => `${value / factor}rem`

export const getP15 = () => {
  return css`
    font-size: ${getRelativeREM(15, REM_MOBILE)};
    font-weight: 200;
    line-height: ${getRelativeREM(20, REM_MOBILE)};
    letter-spacing: 0;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(15, REM_DESKTOP)};
      line-height: ${getRelativeREM(20, REM_DESKTOP)};
    `}
  `
}
export const getP15_1 = () => {
  return css`
    font-size: ${getRelativeREM(15, REM_MOBILE)};
    font-weight: bold;
    line-height: ${getRelativeREM(20, REM_MOBILE)};
    letter-spacing: 0;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(15, REM_DESKTOP)};
      line-height: ${getRelativeREM(20, REM_DESKTOP)};
    `}
  `
}

export const getP18 = () => {
  return css`
    font-size: ${getRelativeREM(14, REM_MOBILE)};
    font-weight: 400;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.01px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(18, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}

export const getP18_1 = () => {
  return css`
    font-size: ${getRelativeREM(15, REM_MOBILE)};
    font-weight: 400;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: +.2px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(28, REM_DESKTOP)};
      line-height: ${getRelativeREM(38, REM_DESKTOP)};
    `}
  `
}

export const getP40 = () => {
  return css`
    font-size: ${getRelativeREM(20, REM_MOBILE)};
    font-weight: 400;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: +.2px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(40, REM_DESKTOP)};
      line-height: ${getRelativeREM(55, REM_DESKTOP)};
    `}
  `
}
export const getP20 = () => {
  return css`
    font-size: ${getRelativeREM(18, REM_MOBILE)};
    font-weight: bold;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.64px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(20, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}
export const getP20_1 = () => {
  return css`
    font-size: ${getRelativeREM(25, REM_MOBILE)};
    font-weight: 400;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: +.2px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(20, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}
export const getP22 = () => {
  return css`
    font-size: ${getRelativeREM(20, REM_MOBILE)};
    font-weight: bold;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.64px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(22, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}
export const getP25 = () => {
  return css`
    font-size: ${getRelativeREM(20, REM_MOBILE)};
    font-weight: bold;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.64px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(25, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}

export const getP25_2 = () => {
  return css`
    font-size: ${getRelativeREM(25, REM_MOBILE)};
    font-weight: bold;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.64px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(25, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}

export const getP25_1 = () => {
  return css`
    font-size: ${getRelativeREM(20, REM_MOBILE)};
    font-weight: 200;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -.64px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(25, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
    `}
  `
}

export const getP35 = () => {
  return css`
    font-size: ${getRelativeREM(24, REM_MOBILE)};
    font-weight: 100;
    line-height: ${getRelativeREM(28, REM_MOBILE)};
    letter-spacing: -.95px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(35, REM_DESKTOP)};
      line-height: ${getRelativeREM(45, REM_DESKTOP)};
    `}
  `
}

export const getP150 = () => {
  return css`
    font-size: ${getRelativeREM(56, REM_MOBILE)};
    font-weight: 100;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -0.9px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(150, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
      letter-spacing: -3px;
    `}
  `
}

export const getP120 = () => {
  return css`
    font-size: ${getRelativeREM(50, REM_MOBILE)};
    font-weight: 100;
    /* line-height: ${getRelativeREM(12, REM_MOBILE)}; */
    letter-spacing: -0.9px;

    ${mediaQuery.greaterThan('tablet')`
      font-size: ${getRelativeREM(120, REM_DESKTOP)};
      // line-height: ${getRelativeREM(12, REM_DESKTOP)};
      letter-spacing: -3px;
    `}
  `
}

